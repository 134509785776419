import React from 'react';
import { 
    Bulletpoint, 
    Button, 
    HeaderText, 
    SubHeader 
} from '../components/defaults';
import money from '../assets/money.svg';
import report from '../assets/report.svg';
import { useRef, useState } from "react";
import loadingIcon from '../assets/loading.svg'
import plant from '../assets/plant.png';
import sample from '../assets/sample.png';
import { useImage } from '../components/useImage';
import { useNavigate } from 'react-router-dom';

const Video = () => {

    const [loaded, setLoaded] = useState(false);
    const [videoSize, setVideoSize] = useState({ width: 'auto', height: 'auto' });
    const videoRef = useRef(null);

    const videoSrc = useImage({
        desktopImage:'https://firebasestorage.googleapis.com/v0/b/specilist-17ea1.appspot.com/o/wide-vid.mp4?alt=media&token=9a4863bd-8162-40f7-bd7d-69383d240cfb',
        mobileImage:'https://firebasestorage.googleapis.com/v0/b/specilist-17ea1.appspot.com/o/mobile-vid.mp4?alt=media&token=715b3893-5e96-49fb-97f2-ac37697ab930'
    })

    
    const handleMetadataLoaded = () => {
        
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        
        setVideoSize({
          width: videoWidth,
          height: videoHeight,
        });
      }

      setLoaded(true)

    };


    return <>
    {!loaded && <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
    <img className="h-10" src={loadingIcon} alt='Loading'/>
    </div>}
    
    <video 
    playsInline
    autoPlay muted 
    loop onLoadedMetadata={handleMetadataLoaded}
    controlsList="nodownload"
    controls={false}
    style={{ height: videoSize.height, width: videoSize.width }}
    className={`
        rounded-lg overflow-hidden
        shadow-2xl
        ${loaded ? 'shadow-2xl' : ''}        
    `}>
        <source src={videoSrc} />
    </video>
    
    {loaded && <img src={plant} className='hidden lg:flex absolute top-[60%] h-[200px] z-10' alt='' />}
    {loaded && <img src={sample} className='hidden lg:flex absolute justify-self-end top-[-20%] h-[200px] mr-[-50px] z-10' alt='' />}

    </>

}






export const Product = () => {

    const navigate = useNavigate();

    return <div className={`
        flex flex-col-reverse items-center justify-center
        xl:flex-row xl:h-screen xl:items-center relative
    `}>
        <div className='absolute top-[-30px]' id='about' />

        
        <div className='xl:h-full flex lg:grid relative items-center justify-center flex-[1.5]'>
            <div className='max-w-[90%] relative justify-self-end flex lg:grid'>
                <Video />
            </div>
        </div>

        <div className='mt-20 xl:m-8 xl:ml-24 xl:mt-0 flex-1 flex flex-col xl:pr-10'>
            <div className='flex flex-col w-[80%] items-center xl:items-start xl:w-[100%] self-center'>
                <HeaderText className='text-center xl:text-left mb-5'>Upload your photos and Let Us Handle the Rest</HeaderText>
                <SubHeader className='text-center xl:text-left mb-6 text-gray-500'>
                    Specilist will instantly find sellers and prices for all the products and finishes in the scene.
                </SubHeader>
                <Bulletpoint icon={money}>Stay ahead by quickly accessing prices, availability, and accurate cost estimates.</Bulletpoint>
                <Bulletpoint icon={report}>Easily generate reports or share extensive product lists with clients and collaborators.</Bulletpoint>
                <Button onClick={() => navigate('/early-access')} className='mb-14 mt-10 xl:mb-0 self-center xl:self-start'>Get Started</Button>
            </div>
        </div>
    </div>


}