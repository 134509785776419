import Footer from "../panels/footer";
import Header from "../panels/header";


export const Privacy = () => {

    return (
        <div className="flex flex-col">
            <Header />
            <div className="max-w-[80%] mb-16 flex self-center flex-col gap-3 font-poppins pt-[100px]">
                <h1><b>Privacy Policy</b></h1>
                <h2>1. Introduction</h2>
                <p>This Privacy Policy explains how Specilist collects, uses, and shares your data. We are committed to protecting your privacy and ensuring transparency in how your information is used.</p>

                <h2>2. Data Collection</h2>
                <p>We collect the following types of data:</p>
                <ul>
                    <li><strong>Personal Information:</strong> When you create an account, we collect your name, email, and other contact details.</li>
                    <li><strong>Uploaded Content:</strong> Images, renders, and other design references you upload to the platform.</li>
                    <li><strong>Usage Data:</strong> Information about how you interact with the platform, including product preferences and selected sellers.</li>
                </ul>

                <h2>3. How We Use Your Data</h2>
                <p>We use the collected data to:</p>
                <ul>
                    <li>Provide and enhance our services.</li>
                    <li>Match you with relevant product sellers and offers.</li>
                    <li>Analyze user behavior to improve platform functionality and user experience.</li>
                </ul>

                <h2>4. Sharing of Data</h2>
                <p>We do not sell your personal data to third parties. We only share read-only data with trusted third-party sellers or service providers to help you get accurate product and price information. This includes sharing product preferences or design specifications, but not your personal contact details unless required for completing a transaction. In some cases, we may be required to share your information with law enforcement or regulatory bodies as required by law.</p>

                <h2>5. Data Security</h2>
                <p>We implement industry-standard measures to protect your data from unauthorized access, disclosure, or alteration. However, no method of data transmission over the Internet is 100% secure, so we cannot guarantee absolute security.</p>

                <h2>6. Your Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Access the data we hold about you.</li>
                    <li>Request corrections to any inaccurate information.</li>
                    <li>Delete your account and associated data (except where retention is required by law).</li>
                    <li>Opt-out of any marketing communications.</li>
                </ul>

                <h2>7. Cookies and Tracking</h2>
                <p>We may use cookies or similar tracking technologies to enhance your experience on the platform and to track usage metrics. You can adjust your browser settings to disable cookies, but doing so may affect your experience with the service.</p>

                <h2>8. Changes to the Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any significant changes will be communicated through our platform. Your continued use of the service after changes have been made constitutes your agreement to the updated policy.</p>
            </div>
            <Footer  />
        </div>
    );


}