import React from 'react';
import { Button, HeaderText, SubHeader } from '../components/defaults';
import { useImage } from '../components/useImage';

import topPanelDesktop from '../assets/top-panel.png';
import topPanelMobile from '../assets/top-panel-mobile.svg';

export const TopPanel = () => {

    const image = useImage({
        desktopImage: topPanelDesktop,
        mobileImage: topPanelMobile,
    });

    return <div className={`
        flex flex-col-reverse items-center justify-center
        lg:flex-row lg:h-screen pt-[80px] lg:items-center 
    `}>
        <div className='flex-[1.5] h-full w-screen lg:w-min'>
            <img src={image} className='h-full w-screen lg:w-min' alt='Specilist' />
        </div>
        <div className='w-[80%] lg:w-min lg:m-10 mt-14 lg:mt-0 flex-1 flex flex-col lg:pr-16'>
            <HeaderText className='text-center lg:text-end mb-6 lg:mb-10'>Stop Wasting Hours Sourcing the Right Products</HeaderText>
            <SubHeader className='text-center lg:text-end mb-12 text-gray-600'>
            With Specialist, just upload one or more photos, and we’ll instantly find the sellers for every product in them. Ready to work smarter?
            </SubHeader>
            
            <a  className='mb-14 lg:mb-0 self-center lg:self-end' href='#learn-more'>
            <Button>Learn More</Button>
            </a>
        </div>
    </div>


}