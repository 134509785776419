import React from 'react';
import pdf from '../assets/pdf.png';
import {
    Button, 
    HeaderText,  
    SubHeader 
} from '../components/defaults';
import { useNavigate } from 'react-router-dom';


export const ExtraFeature = () => {

    const navigate = useNavigate();

    return <div className={`
        flex flex-col items-center justify-center
        xl:flex-row xl:h-screen bg-gray-100 xl:items-center 
        
    `}>
        
        <div className='mt-20 xl:m-10 xl:ml-24 xl:mt-0 flex-1 flex flex-col xl:pr-16'>
            <div className='flex flex-col w-[80%] xl:w-[100%] self-center'>
                <HeaderText className='text-left mb-5'>Stop wasting time drafting specification lists for your clients and team.</HeaderText>
                <SubHeader className='text-left mb-6 text-gray-500'>Effortlessly share an AI powered PDF report or a nice spreadsheet in a few clicks.</SubHeader>
                <Button onClick={() => navigate('/early-access')} className='mb-14 mt-10 xl:mb-0 self-center xl:self-start'>Let's work smarter!</Button>
            </div>
        </div>

        <div className='xl:h-full'>
            <img src={pdf} className='xl:h-full' alt='Specilist' />
        </div>
    </div>


}