import { useEffect, useState } from "react";

export const useImage = ({
    desktopImage,
    mobileImage,
    breakpoint = 1024
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const img = windowWidth >= breakpoint ? desktopImage : mobileImage;

    return img;

}
