import React from 'react';
import logo from '../assets/icon.svg';
import { useNavigate } from 'react-router-dom';


const Header = () => {
    const navigate = useNavigate();


    return (
        <header className="border-b-[0.5px] border-gray-300 
        fixed top-0 left-0 right-0 h-20 flex items-center z-40
        justify-center bg-white">
            <img onClick={() => navigate('/')} src={logo} alt="Specilist" className="cursor-pointer w-32 h-16" />
        </header>
    );
};

export default Header;