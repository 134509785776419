import React from 'react';

const Footer = () => {
    return (
        <footer className="h-[200px] bg-gray-400 flex lg:flex-row flex-col items-center justify-center">
            <a className="font-lora text-white underline mb-2 lg:mb-0 mr-0 lg:mr-5" href='mailto:contact@specilist.com'>Ask us anything!</a>
            <a className="font-lora text-white underline mb-2 lg:mb-0 mr-0 lg:mr-5" href='/tos'>Terms of Service</a>
            <a className="font-lora text-white underline mb-2 lg:mb-0 mr-0 lg:mr-5" href='/privacy'>Privacy Policy</a>

            
            <div className="font-lora text-white">© 2024 SPECILIST all rights reserved</div>
        </footer>
    );
};

export default Footer;