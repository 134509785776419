// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, addDoc, collection } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyD9BpUFiDtM5_lNv4ZdK4m2nL80HTpyLnI",
  authDomain: "specilist-17ea1.firebaseapp.com",
  projectId: "specilist-17ea1",
  storageBucket: "specilist-17ea1.appspot.com",
  messagingSenderId: "589274838300",
  appId: "1:589274838300:web:f86dfb94d2f3babe90f6d9",
  measurementId: "G-XCMLQMV912"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export const sendEmail = async (email) => {
    console.log(email)
    addDoc(collection(db, "users"), {
        email
    });
}