import { SecondaryPanels } from "./secondary";
import { Button, Disclaimer, HeaderText, MegaHeader, SubHeader } from '../components/defaults';
import { useNavigate } from "react-router-dom";

export const Panel5 = () => {

    const navigate = useNavigate();

    return <SecondaryPanels
    height='350px'
    replaceImage={<div className={`
    lg:w-[60%] w-[80%] [&>*]:text-center [&>*]:lg:text-left`}>
        <MegaHeader>
        Streamline your project specifications with Specilist
        </MegaHeader>
        <SubHeader className='mt-10'>
         Save time and effort by creating detailed product lists and reports in minutes.
        </SubHeader>
    </div>}>
        <div>
            <Button onClick={() => navigate('/early-access')} className={'scale-125'}>Get Started</Button>
        </div>
    </SecondaryPanels>

}