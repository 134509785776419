import { useEffect, useState } from "react";
import { Panel, } from "../components/defaults";

export const SecondaryPanels = ({
    wideImage,
    mobileImage,
    height,
    reverseOnMobile,
    reverse,
    children,
    backgroundColor,
    className,
    replaceImage,
    id
}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const img = windowWidth >= 1024 ? wideImage : mobileImage;


    return <Panel 
    id={id}
    backgroundColor={backgroundColor}
    className={`
        ${reverseOnMobile ? 'flex-col-reverse' : 'flex-col'} 
        ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} 
        my-28 px-8 lg:px-0 ${className}
    `} height={height}>

        <div className={`flex-1 flex items-center justify-center
        ${reverseOnMobile ? 'mt-16 lg:mt-0' : 'mb-16 lg:mb-0'}`}>
            {
                replaceImage? replaceImage : <img className="lg:h-full lg:w-full w-[90%] " src={img} alt=''/>
            }
        </div>
        
        <div className={`
        ${reverse ? 'lg:pl-36' : 'lg:pr-36'}
        items-center self-center lg:items-start
        [&>*]:lg:text-left [&>*]:text-center max-w-[80vw] lg:max-w-full
        flex-[0.50] flex flex-col justify-center`}>
            {children}
        </div>
    </Panel>

}