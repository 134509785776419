import React from 'react';
import problemPanel from '../assets/problem-panel.png';
import { 
    Bulletpoint, 
    Button, 
    HeaderText2, 
    SubHeader 
} from '../components/defaults';


export const Problem = () => {

    return <div className={`
        relative
        flex flex-col items-center justify-center
        xl:flex-row xl:h-screen bg-gray-100 xl:items-center 
    `}>
        <div className='absolute top-[-30px]' id='learn-more' />
        <div className='mt-20 xl:m-10 xl:ml-24 xl:mt-0 flex-1 flex flex-col xl:pr-16'>
            <div className='flex flex-col w-[80%] xl:w-[100%] self-center'>
                <HeaderText2 className='text-left mb-10'>Sourcing the right products is a LOT of manual work — especially when you're sourcing countless items.</HeaderText2>
                <Bulletpoint className='text-lg'>Staying within budget while sourcing products is a constant challenge.</Bulletpoint>
                <Bulletpoint className='text-lg'>Finding real sellers and available products quickly is overwhelming.</Bulletpoint>
                <Bulletpoint className='text-lg'>After all the sourcing, you still have to spend time crafting detailed spec and product lists for your clients.</Bulletpoint>
                <a href='#about' className='mb-14 mt-10 xl:mb-0 self-center xl:self-start'>
                    <Button>Discover a better Way</Button>
                </a>
            </div>
        </div>

        <div className='xl:h-full'>
            <img src={problemPanel} className='xl:h-full' alt='Specilist' />
        </div>
    </div>


}