import React, { useEffect } from 'react';
import './App.css';
import { Panel5 } from './panels/05';
import Header from './panels/header';
import Footer from './panels/footer';
import ReactGA from "react-ga4";

import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
  useNavigate
} from 'react-router-dom';
import { Text, MegaHeader,  Button, } from './components/defaults';
import { sendEmail } from './init-firebase';
import { Problem } from './panels_v2/problem';
import { TopPanel } from './panels_v2/top_panel';
import { Product } from './panels_v2/product';
import { ExtraFeature } from './panels_v2/extra_feature';
import { Privacy } from './pages/privacy';
import { Tos } from './pages/tos';


const MainPage = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    })
  }, [])

  return <div 
  className='flex flex-col items-center'
  style={{ maxWidth: 1800, margin: 'auto' }}>
    <div className='w-full'>
      <Header />
      <TopPanel />
      <Problem />
      <Product />
      <ExtraFeature />
      <Panel5 />
      <Footer />
    </div>
  </div>


}

const WaitlistSent = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    })
  }, [])

  return <div 
  className='flex flex-col items-center'
  style={{ maxWidth: 1800, margin: 'auto' }}>
    <Header />
    <div className='flex flex-col h-[100vh] [&>*]:text-center max-w-[80%] items-center justify-center'>
      <MegaHeader className={'mb-5'}>
        Thank you for joining our early access!
      </MegaHeader>
      <Text className={'text-gray-500'}>  
        We're excited to have you with us! We'll reach out as soon as we have a spot ready for you.
      </Text>
    </div>
  </div>
}

const Waitlist = () => {

  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
    })
  }, [])

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const send = () => {
    if (validateEmail(email)) {
      navigate('/sent')
      sendEmail(email);
      window?.heap?.addUserProperties({email});

    } else {
      alert('Please enter a valid email address.');
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') send()
  }

  return <div 
  className='flex flex-col items-center'
  style={{ maxWidth: 1800, margin: 'auto' }}>
    <Header />
    <div className='
    flex lg:max-w-[600px] mt-16 max-w-[80%] flex-col w-full pt-32 items-center
    [&>*]:text-center'>    
      <MegaHeader className={'mb-5'}>Join Our Limited Early Access!</MegaHeader>
      <Text className={'text-gray-500'}>
      Specilist is currently onboarding a limited number of users to ensure a smoother experience for our first users.
      Leave your email below to be part of our early access and experience Specilist before everyone else!
      </Text>
      
      <div className='flex gap-3 mt-10 w-[100%] flex-col lg:flex-row'>
        <input onKeyDown={onKeyDown} value={email} onChange={(e) => setEmail(e.target.value)} className='h-[50px] lg:flex-1 bg-gray-100 px-4
        rounded-sm font-poppins outline-none' placeholder='Your email here' />
        <Button onClick={send} disabled={email.length === 0} className='whitespace-nowrap'>Get Early Access</Button> 
      </div>
      
    </div>

  </div>


}


function App() {
  
  return <Router>
    <Routes>
      <Route path='/' element={<MainPage />} />
      <Route path='/early-access' element={<Waitlist />} />
      <Route path='/sent' element={<WaitlistSent />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/tos' element={<Tos />} />
    </Routes>
  </Router>
}

export default App;