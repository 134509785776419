import Footer from "../panels/footer";
import Header from "../panels/header";

export const Tos = () => {

    return (
        <div className="flex flex-col">
            <Header />
            <div className="max-w-[80%] mb-16 flex self-center flex-col gap-3 font-poppins pt-[100px]">
            

            <h1><b>Terms of Service</b></h1>
            <p>Welcome to Specilist. By accessing or using our platform, you agree to comply with these Terms of Service. Please read them carefully before using our services.</p>

            <h2>Services Provided</h2>
            <p>Specilist allows users to upload design references and create product lists by finding relevant sellers. Our service offers real-time price checks, availability, and the ability to export lists into various formats.</p>

            <h2>User Responsibilities</h2>
            <ul>
                <li>Users must provide accurate information when using our platform.</li>
                <li>You are responsible for the content you upload, ensuring it does not infringe any intellectual property rights.</li>
                <li>Users must not misuse our services, including attempting to hack, reverse-engineer, or interfere with the platform’s functionality.</li>
            </ul>

            <h2>Service Availability</h2>
            <p>We aim to ensure that the service operates smoothly at all times. However, we do not guarantee uninterrupted access, and Specilist may temporarily suspend or modify services without prior notice for maintenance or other purposes.</p>

            <h2>Third-Party Sellers</h2>
            <p>Our platform provides links and recommendations for third-party sellers. We are not responsible for the quality, accuracy, or fulfillment of third-party products. Transactions with third-party sellers are solely between you and the seller.</p>

            <h2>Data Usage</h2>
            <p>By using the service, you agree that Specilist can use and share data as outlined in our Privacy Policy. You retain ownership of your uploaded content, but grant Specilist the right to use this data to provide and improve services.</p>

            <h2>Termination</h2>
            <p>Specilist reserves the right to suspend or terminate your account if we suspect misuse, violation of these terms, or any illegal activity.</p>

            <h2>Limitation of Liability</h2>
            <p>Specilist is not responsible for any indirect, incidental, or consequential damages arising from the use of our services, including but not limited to errors in pricing or product details from third-party sellers.</p>

            <h2>Changes to the Terms</h2>
            <p>We may update these Terms of Service from time to time. By continuing to use the service after changes are made, you agree to the revised terms.</p>
        </div>
        <Footer />
        </div>
    );


}