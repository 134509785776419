
export const Bulletpoint = ({ children, icon, className }) => {

    return <div className={
        `font-lora text-base font-semibold text-gray-500
        pl-3 mb-5 w-[80%] items-center flex
        ${!icon ? 'border-gray-500 border-l-[8px]' : ''}
        ${className}`
    }>
        {icon && <img src={icon} className='w-6 h-6 mr-3 inline' alt='' />}
        {children}
    </div>

}


export const HeaderText = ({ className, ...props }) => {
    return <div 
    className={`lg:text-[40px] leading-tight font-bold font-lora 
    text-[30px]  
    ${className}`}
    {...props} />
  }

export const HeaderText2 = ({ className, ...props }) => {
    return <div 
    className={`lg:text-[30px] leading-tight font-bold font-lora 
    text-[30px]  
    ${className}`}
    {...props} />
}

export const SubText = ({ className, ...props }) => {
    return <div 
    className={`text-[16px] font-lora text-gray-500 ${className}`}
    {...props} />
}

export const SubHeader = ({ className, color, ...props }) => {
    return <div
    className={`text-[20px] font-semibold font-lora ${className}`}
    style={color ? { color } : {}}
    {...props} />
}

export const PreHeader = ({ className, color, ...props }) => {
    return <div
    className={`text-[20px] font-bold font-lora ${className}`}
    style={color ? { color } : {}}
    {...props} />
}

export const MegaHeader = ({ className, ...props }) => {
    return <div 
    className={`lg:text-[45px] text-[36px] leading-tight font-bold font-lora ${className}`}
    {...props} />
}

export const Disclaimer = ({ className, ...props }) => {
    return <div 
    className={`text-[12.8px] text-gray-400 font-lora ${className}`}
    {...props} />
}


export const Text = ({ className, ...props }) => {
    return <div 
    className={`text-[16.8px] text-gray-400 font-poppins ${className}`}
    {...props} />
}

export const Button = ({ className, ...props}) => {
    return <button
    className={`bg-[#FFED00] cursor-pointer  text-gray-700 shadow-xl text-[22px] font-lora font-bold rounded px-8 py-2 ${className}`}
    {...props} />
}

export const Panel = ({ backgroundColor, className, height, ...props }) => {
    return <div
    style={{
        '--height': `${height}`,
        '--bgColor': `${backgroundColor ? backgroundColor : 'white'}`
    }}
    className={`
    bg-[--bgColor]
    flex lg:h-[--height] lg:flex-row flex-col 
    ${className}`}
    {...props} />
}
